<template>
  <div>
    <div class="cart-card" v-for="(shop, s_index) in list" :key="s_index">
      <!-- title -->
      <div class="shop-title" @click="setShopAll(s_index)" @click.stop>
        <!-- 全选按钮 -->
        <div
          class="select-all"
          :class="{ 'is-show-back': shop.is_select * 1 === 1 }"
        >
          <van-icon
            name="success"
            style="display: none"
            :class="{ 'is-show-icon': shop.is_select * 1 == 1 }"
            color="#fff"
          />
        </div>
        <div class="shop-info" @click="goToUrl(2, shop.shop_id)">
          <!-- <van-icon name="shop-collect-o" class="shop-icon" /> -->
          <img src="~@/assets/common/store_icon@2x.png" class="shop-img" />
          <span class="title-text">供应商：{{ shop.shop_name }}</span>
        </div>
        <van-icon name="arrow" size="16" color="#C6C6C6" />
        <van-icon
          name="delete-o"
          class="delete-icon"
          size="16"
          color="#C6C6C6"
          @click="isDelete(s_index, -1)"
          @click.stop
        />
      </div>
      <!-- list -->
      <div @click.stop>
        <van-swipe-cell
          ref="swipeDle"
          stop-propagation
          v-for="(goods, g_index) in shop.goods_list"
          :key="g_index"
        >
          <div
            class="shop-list"
            @click.stop
            @click="goToUrl(1, goods.goods_id)"
          >
            <div class="shop-list-content">
              <!-- 单选按钮 -->
              <div
                class="select-all"
                :class="{ 'is-show-back': goods.is_select * 1 == 1 }"
                @click="onSelectRadio(s_index, g_index)"
                @click.stop
              >
                <van-icon
                  name="success"
                  style="display: none"
                  :class="{ 'is-show-icon': goods.is_select * 1 == 1 }"
                  color="#fff"
                />
              </div>
              <!-- checkbox -->
              <div class="content-text">
                <!-- img -->
                <div class="list-content-img">
                  <!-- 消费金
                <img src="" alt="" /> -->
                  <!-- goods-img -->
                  <img :src="goods.goods_img" class="goods-img" />
                </div>
                <!-- content -->
                <div class="goods-info">
                  <div class="goods-title">
                    {{ goods.goods_name }}
                  </div>
                  <span class="goods-size">
                    <span>{{ goods.item_name }}</span>
                  </span>
                  <div class="goods-price" @click.stop>
                    <div class="present-price">
                      <span class="small">￥</span>
                      <span class="integer">{{
                        parseInt(goods.spec_price)
                      }}</span>
                      <span class="small">{{ twoNum(goods.spec_price) }}</span>
                    </div>
                    <span class="original-price">
                      <span>￥</span>
                      <span>
                        {{ parseInt(goods.spec_pricing) }}
                      </span>
                      <span>
                        {{ twoNum(goods.spec_pricing) }}
                      </span>
                    </span>
                    <van-stepper
                      v-model="goods.number"
                      mix="1"
                      :max="goods.spec_stock"
                      button-size="26"
                      @change="setGoodsNum(s_index, g_index)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template #right>
            <van-button
              square
              text="删除"
              type="danger"
              class="delete-button"
              @click="confirmBtnId(goods.id, g_index)"
            />
          </template>
        </van-swipe-cell>
      </div>
    </div>
    <van-popup v-model="showPopup">
      <span class="is-del">是否确认删除</span>
      <div class="is-button">
        <div class="button">
          <van-button type="danger" plain round block @click="cancelBtn">
            取消
          </van-button>
        </div>
        <div class="button">
          <van-button type="danger" round block @click="confirmBtn">
            确认
          </van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  delShopping,
  getShoppingGoods,
  saveShoppingNum,
  setSelect,
} from "@/api/shopping";
import tools from "@/common/js/tools";
import { Toast } from "vant";

export default {
  name: "cart-list",
  data() {
    return {
      value: 1,
      selectAll: false,
      num: 1,
      result: [],
      list: [],
      isShowAll: false,
      isShowRadio: false,
      showPopup: false, //弹出层控制
      delIds: [],
    };
  },
  mounted() {
    this.getShoppingGoods();
  },
  methods: {
    goToUrl(type, data) {
      //页面跳转
      // return false;
      switch (type) {
        case 1:
          //订单详情
          this.$router.push({
            path: "/goods",
            query: {
              goodsId: data,
              type: 1,
            },
          });
          break;
        case 2:
          //店铺详情
          this.$router.push({
            name: "shop-home",
            query: { shopId: data },
          });
          break;
        case 3:
          // this.$router.push({name: 'my-collection'});
          break;
        case 4:
          break;
        default:
      }
    },
    onSelectAll() {
      this.isShowAll = !this.isShowAll;
    },
    async onSelectRadio(sIndex, gIndex) {
      //设置选中状态
      if (this.isAjax) {
        return false;
      }
      this.isAjax = true;
      let is_select =
        this.list[sIndex].goods_list[gIndex].is_select * 1 == 0 ? 1 : 0;
      this.list[sIndex].goods_list[gIndex].is_select = is_select;
      this.setSelect(this.list[sIndex].goods_list[gIndex].id, is_select);
      this.isAjax = false;
      this.verifyIsAll();
    },
    setShopAll(sIndex) {
      // 设置店铺商品全选
      let is_select = this.list[sIndex].is_select * 1 == 0 ? 1 : 0;
      this.list[sIndex].is_select = is_select;
      let shoppingIds = [];
      for (let g_i in this.list[sIndex].goods_list) {
        this.list[sIndex].goods_list[g_i].is_select = is_select;
        shoppingIds[g_i] = this.list[sIndex].goods_list[g_i].id;
      }
      shoppingIds = shoppingIds.join(",");
      this.setSelect(shoppingIds, is_select);
      this.verifyIsAll();
    },
    setAll(isAll) {
      // 设置全选
      let is_select = isAll ? 1 : 0;
      let shoppingIds = [];
      for (let s_i in this.list) {
        for (let g_i in this.list[s_i].goods_list) {
          this.list[s_i].goods_list[g_i].is_select = is_select;
          shoppingIds[shoppingIds.length] = this.list[s_i].goods_list[g_i].id;
        }
        this.list[s_i].is_select = is_select;
      }
      shoppingIds = shoppingIds.join(",");
      this.setSelect(shoppingIds, is_select);
      this.setTotalPrices();
    },
    verifyIsAll() {
      //验证是否选中
      let isAll = true;
      for (let s_i in this.list) {
        let shopAll = 1;
        for (let g_i in this.list[s_i].goods_list) {
          if (this.list[s_i].goods_list[g_i].is_select * 1 == 0) {
            shopAll = 0;
            break;
          }
        }
        this.list[s_i].is_select = shopAll;
        if (shopAll === 0) {
          isAll = false;
          break;
        }
      }
      //通知父级是否选中
      this.$emit("verifyIsAll", isAll);
      this.setTotalPrices();
    },
    setTotalPrices() {
      // 计算总价
      let totalPrices = 0;
      for (let s_i in this.list) {
        for (let g_i in this.list[s_i].goods_list) {
          if (this.list[s_i].goods_list[g_i].is_select * 1 == 1) {
            totalPrices +=
              this.list[s_i].goods_list[g_i].spec_price *
              this.list[s_i].goods_list[g_i].number;
          }
        }
      }
      this.$emit("setTotalPrices", {
        totalPrices: totalPrices,
        totalNum: this.list.length,
      });
    },
    isDelete(sIndex, gIndex) {
      this.showPopup = true;
      this.delIds = [];
      for (let g_i in this.list[sIndex].goods_list) {
        if (gIndex < 0) {
          this.delIds[this.delIds.length] =
            this.list[sIndex].goods_list[g_i].id;
        } else if (gIndex == g_i) {
          this.delIds[this.delIds.length] =
            this.list[sIndex].goods_list[g_i].id;
        }
      }
    },
    cancelBtn() {
      this.showPopup = false;
    },
    confirmBtnId(delId, g_index) {
      this.delIds = [delId];
      this.confirmBtn();
    },
    async confirmBtn() {
      //执行删除
      if (this.delIds.length <= 0) {
        Toast.fail("删除商品信息错误");
        this.showPopup = false;
        return false;
      }
      let shoppingIds = this.delIds.join(",");
      const ret = await delShopping({ shopping_ids: shoppingIds });
      if (ret.code * 1 == 1) {
        Toast.success("删除成功");
        for (let s_i in this.list) {
          if (this.delIds.length > 1) {
            this.list.splice(s_i, 1);
          } else {
            for (let g_i in this.list[s_i].goods_list) {
              let delKey = this.delIds.indexOf(
                this.list[s_i].goods_list[g_i].id
              );
              if (delKey >= 0) {
                this.list[s_i].goods_list.splice(g_i, 1);
              }
            }
            for (let d_i in this.list) {
              if (this.list[d_i].goods_list.length <= 0) {
                this.list.splice(d_i, 1);
              }
            }
          }
        }
        this.verifyIsAll();
      }
      tools.delShoppingNum();
      this.showPopup = false;
    },
    async setSelect(shoppingIds, isSelect) {
      //选中请求
      const ret = await setSelect({
        shopping_ids: shoppingIds,
        is_select: isSelect,
      });
    },
    async setGoodsNum(sIndex, gIndex) {
      //需改购物车数量
      const sId = this.list[sIndex].goods_list[gIndex].id;
      let number = this.list[sIndex].goods_list[gIndex].number;
      if (number > this.list[sIndex].goods_list[gIndex].spec_stock) {
        number = this.list[sIndex].goods_list[gIndex].spec_stock;
        this.list[sIndex].goods_list[gIndex].number = number;
      }
      const ret = await saveShoppingNum({ shopping_id: sId, number: number });
      if (ret.code * 1 == 1) {
        //购物车数量增加成功
        this.setTotalPrices();
      }
    },
    twoNum(num) {
      // 获取两位小数
      let price = String(num).split(".")[1];
      price === undefined ? (price = ".00") : (price = `.${price}`);
      return price;
    },
    onChange() {
      if (this.selectAll) {
        this.$refs.checkboxGroup.toggleAll(true);
      } else {
        this.$refs.checkboxGroup.toggleAll(false);
      }
    },
    onChecked(index) {},
    async getShoppingGoods() {
      //获取购物车数据
      const ret = await getShoppingGoods();
      if (ret.code * 1 == 1) {
        this.list = ret.data;
        this.verifyIsAll();
      }
    },
    submitOrder() {
      let shoppingIds = [];
      for (let s_i in this.list) {
        for (let g_i in this.list[s_i].goods_list) {
          if (this.list[s_i].goods_list[g_i].is_select * 1 == 1) {
            shoppingIds[shoppingIds.length] = this.list[s_i].goods_list[g_i].id;
          }
        }
      }
      if (shoppingIds.length <= 0) {
        Toast.fail("暂无选中的商品");
        return false;
      }
      shoppingIds = shoppingIds.join(",");
      this.$router.push({
        name: "create-order",
        query: { shoppingIds: shoppingIds },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.is-del {
  font-size: 16px;
}
.van-swipe-cell {
  margin-top: 20px;
  &:nth-child(1) {
    margin-top: 6px;
  }
}
// 复选框样式
// 全选
.select-all {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border: 1px solid #cecfd2;
  border-radius: 50%;
}

.is-show-back {
  border: 0;
  background-color: #ed3724;
  transition: all 0.3s;
}

.is-show-icon {
  display: block !important;
  transition: all 0.3s;
}

// 单选
// 商品信息样式
.shop-list {
  padding-top: 12px;

  .shop-list-content {
    display: flex;
    align-items: center;

    .content-text {
      display: flex;
      align-items: center;
    }
  }

  .list-content-img {
    width: 90px;
    margin: 0 12px;
    border-radius: 6px;
    overflow: hidden;
    .goods-img {
      display: block;
      width: 90px;
      height: 90px;
    }
  }
}

.icon-delete {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

//组件样式
.cart-card {
  margin-top: 12px;
  background-color: #fff;
  border-radius: 16px;
  padding: 10px 10px 20px 10px;
}

.delete-button {
  height: 100%;
}

.shop-title {
  display: flex;
  align-items: center;
  position: relative;

  .shop-info {
    margin-left: 12px;
    display: flex;
    align-items: center;
    .shop-img {
      display: block;
      width: 21px;
      height: 21px;
      margin: 4px;
    }
  }

  .shop-icon {
    margin: 0 8px 0 0;
    font-size: 18px;
    color: #c6c6c6;
  }

  .title-text {
    font-size: 17px;
    font-weight: 500;
  }

  .delete-icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}

.goods-info {
  width: 234px;

  .goods-title {
    width: 90%;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.goods-size {
  display: inline-block;
  width: auto;
  font-size: 14px;
  border-radius: 4px;
  color: #6f6f6f;
  padding: 4px;
  background-color: #f2f2f2;
  margin: 6px 0;
}

.goods-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .present-price {
    color: #ed301d;
    font-size: 14px;
  }
  .integer {
    font-weight: 600;
    font-size: 18px;
  }
  .original-price {
    color: #707070;
    text-decoration: line-through;
    font-size: 12px;
  }
}

// 弹出层
.van-popup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 30px 14px;
  width: 320px;
  height: 190px;
  border-radius: 16px;
  box-sizing: border-box;

  .is-button {
    display: flex;
    justify-content: space-around;
    width: 100%;

    .button {
      width: 116px;
    }
  }
}
</style>
