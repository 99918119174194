<template>
  <div class="layout-box">
    <div class="left-icon">
      <van-icon
        name="arrow-left"
        class="left-icon-img"
        size="20"
        color="#9B9B9B"
        @click="$router.go(-1)"
      />
      <span class="title-cart">购物车</span>
    </div>
    <div v-if="totalNum > 0" class="layout">
      <CartList
        ref="cartList"
        @setTotalPrices="setTotalPrices"
        @verifyIsAll="verifyIsAll"
      >
      </CartList>
    </div>
    <!-- 空白页 -->
    <div v-else class="blank-page-layout">
      <div class="blank-page">
        <img src="~@/assets/common/shopping_cart_blank_img@2x.png" alt="" />
        <span>购物车是空的</span>
      </div>
    </div>
    <GoodsShowLabel :tagId="0" :sendType="3" class="layout-label">
      <div slot="detailsTitle" class="title-recommend">- 为你推荐 -</div>
    </GoodsShowLabel>
    <van-submit-bar
      :price="totalPrices"
      button-text="提交订单"
      @submit="submitOrder()"
    >
      <van-checkbox v-model="checked" checked-color="#ED3724" @click="setAll()">
        全选
      </van-checkbox>
    </van-submit-bar>
  </div>
</template>

<script>
import GoodsShowLabel from "@/components/goods-show/goods-show-label";
import CartList from "./moudles/cart-list";
export default {
  name: "",
  components: {
    CartList,
    GoodsShowLabel,
  },
  data() {
    return {
      checked: true,
      totalPrices: 0,
      totalNum: 1,
    };
  },
  methods: {
    setAll() {
      this.$refs.cartList.setAll(this.checked);
    },
    verifyIsAll(checked) {
      this.checked = checked;
    },
    setTotalPrices(ret) {
      this.totalPrices = ret.totalPrices * 100;
      this.totalNum = ret.totalNum;
    },
    submitOrder() {
      this.$refs.cartList.submitOrder();
    },
  },
};
</script>

<style lang="less" scoped>
.layout-box {
  padding-bottom: 100px;
}
.title-cart {
  font-size: 21px;
}
.layout {
  padding: 0 12px;
}
.blank-page-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 278px;
  background-color: #fff;
  .blank-page {
    font-size: 18px;
    color: #6f6f6f;
    text-align: center;
    width: 180px;
    img {
      width: 100%;
    }
  }
}
.left-icon {
  position: relative;
  width: 100%;
  height: 57px;
  line-height: 57px;
  text-align: center;
  background-color: #fff;
  border-bottom: 1px solid rgb(241, 241, 241);
  .left-icon-img {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }
}
.title-recommend {
  font-size: 18px;
  font-weight: 500;
}
// 提交订单
.van-submit-bar {
  bottom: 63px;
  border-bottom: 1px solid rgb(241, 241, 241);
  z-index: 1;
}
/deep/.van-submit-bar__bar {
  height: 63px;
}
</style>
